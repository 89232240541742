<template>
  <div class="alert-mask " :class="active ? 'active' : ''">
    <div class="popup-area large scroll-pop" :class="active ? 'active' : ''">
      <div class="pop-header">
        <h1 class="pop-title">Transaction History Detail</h1>
        <button class="pop-x pop_close_btn" @click="$emit('close')">
          닫기
        </button>
      </div>
      <div class="pop-contents">
        <div class="noPlugin-scroll">
          <!-----------------log start -------------------->
          <article class="board-view">
            <div class="table-horizon st01">
              <table class="form-md">
                <colgroup>
                  <col style="width:15%" />
                  <col style="width:32%" />
                  <col style="width:21%" />
                  <col style="width:32%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label class="ic-star table-label">Sequence No.</label>
                    </th>
                    <td>
                      <span class="isf">{{ trnscSn || '-' }}</span>
                    </td>
                    <th></th>
                    <td></td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label">User ID</label>
                    </th>
                    <td>
                      <span class="isf">{{ userId || '-' }}</span>
                    </td>
                    <th>
                      <label class="ic-star table-label">Service ID</label>
                    </th>
                    <td>
                      <span class="isf">{{ srvcId || '-' }}</span>
                    </td>
                    <th></th>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label">Server Type</label>
                    </th>
                    <td>
                      <span class="isf">{{ appProtNm || '-' }}</span>
                    </td>
                    <th>
                      <label class="ic-star table-label">Request Date</label>
                    </th>
                    <td>
                      <span class="isf">{{
                        common_getDateString(requstDt)
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label">Request IP</label>
                    </th>
                    <td>
                      <span class="isf">{{ requstIp || '-' }}</span>
                    </td>
                    <th>
                      <label class="ic-star table-label">Request Type</label>
                    </th>
                    <td>
                      <span class="isf">{{ requstMthdNm || '-' }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label">Request URL</label>
                    </th>
                    <td colspan="3">
                      <span class="isf">{{ requstUri || '-' }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label">Request Headers</label>
                    </th>
                    <td colspan="3">
                      <span class="isf">
                        <span class="pre">{{
                          getJSONPrettyPrint(requstHder)
                        }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label">Request Body</label>
                    </th>
                    <td colspan="3">
                      <span class="isf">
                        <span class="pre">{{ setPld('req', requstPld) }}</span>
                        <button
                          type="submit"
                          class="bt-md bt-default"
                          v-if="btnReqFlag"
                        >
                          <span>Download</span>
                        </button>
                      </span>
                    </td>
                  </tr>
                  <tr style="border-top: 1px solid #ddd;">
                    <th></th>
                    <td colspan="3"></td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label">Response Date</label>
                    </th>
                    <td>
                      <span class="isf">{{ rspnsDt || '-' }}</span>
                    </td>
                    <th>
                      <label class="ic-star table-label">Response Code</label>
                    </th>
                    <td>
                      <span class="isf">{{ rspnsCode || '-' }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label"
                        >Response Headers</label
                      >
                    </th>
                    <td colspan="3">
                      <span class="isf">
                        <span class="pre">{{ rspnsHder || '-' }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label">Response Body</label>
                    </th>
                    <td colspan="3">
                      <span class="isf">
                        <span class="pre">{{ setPld('res', rspnsPld) }}</span>
                        <button
                          type="submit"
                          class="bt-md bt-default"
                          v-if="brnResFlag"
                        >
                          <span>Download</span>
                        </button>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label">Error Code</label>
                    </th>
                    <td colspan="3">
                      <span class="isf">
                        <span class="pre">{{ errorCd || '-' }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star table-label"
                        >Error Description</label
                      >
                    </th>
                    <td colspan="3">
                      <span class="isf">
                        <span class="pre">{{ errorMsg || '-' }}</span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </article>
          <!------------// 게시판 내용 end ------------------>
          <div class="btns-bottom">
            <button
              type="button"
              @click="$emit('close')"
              class="bt-md bt-black pop_close_btn"
            >
              Close
            </button>
          </div>
        </div>
        <!--noPlugin-scroll-->
      </div>
      <!--/pop-contents-->
    </div>
  </div>
</template>
<script>
import CommonMixins from '@/mixins/CommonMixins.js';
import { fetchLogDetail } from '@/api/serviceApi.js';
export default {
  mixins: [CommonMixins],
  props: {
    trnscSn: {},
  },
  created() {
    this.fetchLogDetail();
  },
  data() {
    return {
      active: false,
      userId: '',
      srvcId: '',
      appProtNm: '',
      requstDt: '',
      requstIp: '',
      requstMthdNm: '',
      requstUri: '',
      requstHder: '',
      requstPld: '',
      rspnsDt: '',
      rspnsCode: '',
      rspnsHder: '',
      rspnsPld: '',
      errorCd: '',
      errorMsg: '',
      btnReqFlag: true,
      brnResFlag: true,
    };
  },
  methods: {
    async fetchLogDetail() {
      const { data } = await fetchLogDetail({
        trnscSn: this.trnscSn,
      }).catch(() => this.$emit('close'));
      Object.assign(this.$data, data);
      this.active = true;
    },
    getJSONPrettyPrint(str) {
      try {
        return JSON.stringify(this.getFormatJson(str), 2, 2);
      } catch (e) {
        return str;
      }
    },

    getFormatJson(jsonVal) {
      if (
        jsonVal == '' ||
        jsonVal == null ||
        jsonVal == undefined ||
        jsonVal == 'undefined'
      ) {
        return '';
      } else {
        return JSON.parse(jsonVal);
      }
    },
    downloadPayload(type) {
      location.href = `${process.env.VUE_APP_URL_PORTAL}/service/log/payload/${this.trnscSn}/${type}?token=${this.$store.getter['auth/portalToken']}`;
    },
    // 응답 / 요청 Body 세팅
    setPld(type, pld) {
      if (!pld) {
        if (type === 'req') {
          this.btnReqFlag = false;
        } else {
          this.brnResFlag = false;
        }
        return '-';
      }
      if (pld.length > 100000) {
        let payloadSub = pld?.substring(0, 3000);
        payloadSub = payloadSub.replace(/\t/g, '');
        payloadSub = payloadSub.replace(/\n/g, '');
        payloadSub = payloadSub.replace(/\r/g, '');
        payloadSub = payloadSub.replace(/\r\n/g, '');
        payloadSub += ' ... \n(length: ' + pld.length + ')';
        if (type === 'req') {
          this.btnReqFlag = true;
          return payloadSub;
        } else {
          this.brnResFlag = true;
          return payloadSub;
        }
      } else if (pld?.length > 0) {
        if (type === 'req') {
          this.btnReqFlag = true;
          return this.getJSONPrettyPrint(this.requstPld);
        } else {
          this.brnResFlag = true;
          return this.getJSONPrettyPrint(this.rspnsPld);
        }
      }
    },
  },
};
</script>
<style></style>
