import { serviceInterceptor } from './index';

function fetchServceDetail() {
  return serviceInterceptor.get(`management/service`);
}

function updateService(params) {
  return serviceInterceptor.post(`/management/service/update`, params);
}

function fetchOperatorDetail() {
  return serviceInterceptor.get(`/management/operator`);
}

function updateOperator(params) {
  return serviceInterceptor.post(`/management/operator/update`, params);
}

function checkPassword(params) {
  return serviceInterceptor.post(`/session/password`, params);
}

function updatePassword(params) {
  return serviceInterceptor.post(
    `/management/operator/update/password`,
    params,
  );
}

function fetchUserList(params) {
  return serviceInterceptor.post(`/user/list`, params);
}

function fetchUserDetail({ userId }) {
  return serviceInterceptor.post(`/user/detail`, { userId });
}

function fetchUserResrouce(params) {
  return serviceInterceptor.post(`/user/resrce`, params);
}

function fetchUserResourceList(params) {
  return serviceInterceptor.post(`/user/resrce/list`, params);
}

function fetchLogList(params) {
  return serviceInterceptor.post(`/log/list`, params);
}

function fetchLogDetail({ trnscSn }) {
  return serviceInterceptor.post(`/log/detail`, { trnscSn });
}

export {
  fetchServceDetail,
  fetchOperatorDetail,
  checkPassword,
  updateService,
  updateOperator,
  updatePassword,
  fetchUserList,
  fetchUserDetail,
  fetchUserResrouce,
  fetchUserResourceList,
  fetchLogList,
  fetchLogDetail,
};
