<template>
  <div>
    <div class="container">
      <sub-header
        :pathList="['Home', 'Service Admin', 'Transaction History']"
        :title="{
          mainTitle: 'Service Admin',
          smallTitle: 'Transaction History',
        }"
      />

      <!-- contents -->
      <div id="contents" class="contents">
        <div id="list">
          <div class="title-sort-box">
            <h3>Trans</h3>
            <div class="sort-side">
              <label class="search-label ic-bullet">User Id</label>
              <span
                class="search-icon-wrap"
                style="width: 120px; border: 1px solid #ddd;"
              >
                <input
                  type="text"
                  class="isf"
                  v-model="userId"
                  maxlength="20"
                  dataType="LITERAL"
                  v-on:keyup.enter="onSearch"
                />
              </span>
              <label class="search-label ic-bullet">Server Type</label>
              <span style="width: 120px;">
                <select class="isf term-sel" v-model="appProtNm">
                  <option value="">All</option>
                  <option value="fhir">Fhir</option>
                  <option value="openapi">Openapi</option>
                </select>
              </span>
              <label class="search-label ic-bullet">Result Code</label>
              <span
                class="search-icon-wrap"
                style="width: 120px; border: 1px solid #ddd;"
              >
                <input
                  type="text"
                  class="isf"
                  maxlength="20"
                  dataType="LITERAL"
                  v-model="rspnsCode"
                  v-on:keyup.enter="onSearch"
                />
              </span>
              <label class="search-label ic-bullet">Request Date</label>
              <span class="daterange-wrap">
                <date-picker
                  v-model="rangeDate"
                  mode="date"
                  is-range
                  :columns="2"
                  :step="1"
                >
                  <template #default="{ inputValue, togglePopover}">
                    <input
                      type="text"
                      class="isf"
                      style="width: 225px;"
                      dataType="LITERAL"
                      id="findDateDaterangepicker"
                      :value="
                        inputValue.start != null
                          ? `${inputValue.start} ~ ${inputValue.end}`
                          : null
                      "
                      autocomplete="off"
                      v-on:keyup.enter="onSearch"
                      @click="togglePopover()"
                    />
                    <label
                      for="findDateDaterangepicker"
                      @click="togglePopover()"
                      class="ic-calendar"
                    ></label>
                  </template>
                </date-picker>
              </span>
              <button
                type="submit"
                class="bt-md bt-default"
                @click="onSearch"
                style="margin-left: 10px; vertical-align: top;"
              >
                <span>Search</span>
              </button>
            </div>
          </div>
          <!--/title-sort-box-->
          <list-form
            :fetchApi="fetchLogList"
            :columns="columns"
            @detail="openTrasactionPopup"
            ref="trasactionList"
          />
        </div>
      </div>
      <!-- /contents -->
    </div>
    <transaction-detail-popup
      v-if="trasactionPopup"
      @close="trasactionPopup = false"
      :trnscSn="trnscSn"
    />
  </div>
</template>
<script>
import ListForm from '@/components/common/ListForm.vue';
import { fetchLogList } from '@/api/serviceApi.js';
import CommonMixins from '@/mixins/CommonMixins.js';
import TransactionDetailPopup from './TransactionDetailPopup.vue';

export default {
  mixins: [CommonMixins],
  components: { ListForm, TransactionDetailPopup },
  data() {
    return {
      columns: [
        {
          label: 'Sequence No.',
          field: 'trnscSn',
          sortable: false,
          width: '10%',
        },
        {
          label: 'User ID',
          field: 'userId',
          width: '12.5%',
        },
        {
          label: 'Service ID',
          field: 'srvcId',
          sortable: false,
          width: '12.5%',
        },
        {
          label: 'Server Type',
          field: 'appProtNm',
          sortable: false,
          width: '10%',
        },
        {
          label: 'Reqeust Type',
          field: 'requstMthdNm',
          sortable: false,
          width: '10%',
        },
        {
          label: 'URL',
          field: 'requstUri',
          sortable: false,
          width: '20%',
        },
        {
          label: 'Result Code',
          field: 'rspnsCode',
          width: '10%',
        },
        {
          label: 'Request Date',
          field: 'requstDt',
          formatFn: val => CommonMixins.methods.common_getDateString(val),
          width: '12%',
        },
      ],
      rangeDate: {
        start: this.dateCalculation(),
        end: this.dateCalculation(6),
      },
      userId: '',
      appProtNm: '',
      rspnsCode: '',
      trasactionPopup: false,
      trnscSn: '',
    };
  },
  methods: {
    fetchLogList(params) {
      return fetchLogList(params);
    },
    // 상세 보기 팝업
    openTrasactionPopup({ trnscSn }) {
      this.trasactionPopup = true;
      this.trnscSn = trnscSn;
    },
    // 검색
    onSearch() {
      let params = {
        page: 1,
        userId: this.userId,
        appProtNm: this.appProtNm,
        rspnsCode: this.rspnsCode,
        startDateString: this.rangeDate.start
          ? this.dateFormat(this.rangeDate.start)
          : '',
        endDateString: this.rangeDate.end
          ? this.dateFormat(this.rangeDate.end)
          : '',
      };

      this.$refs.trasactionList.onSearch(params);
    },

    dateFormat: date => {
      let newDate = new Date(date);
      let year = newDate.getFullYear(); //yyyy
      let month = 1 + newDate.getMonth(); //M
      month = month >= 10 ? month : '0' + month; //month 두자리로 저장
      let day = newDate.getDate(); //d
      day = day >= 10 ? day : '0' + day; //day 두자리로 저장
      return year + '-' + month + '-' + day;
    },
    dateCalculation(day) {
      let date = new Date();
      return day ? date.setDate(date.getDate() - day) : date;
    },
  },
};
</script>
<style scoped>
@import url('./transaction.css');
</style>
